import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuarios: [],
    token:'',
    cargaDatos: false,
    drawerRight: false,
    widgetMedico:false,
    countMensajes: 0,
    notificacion: { show: false, title: '', mensaje: '', color: '' },
  },
  mutations: {
    obtenerUsuario(state, data){
      state.token = data.token;
    },
    setDrawerRight(state, data) {
      state.drawerRight = data;
    },
    setWidgetMedico(state, data) {
      state.widgetMedico = data;
    },
    setCargaDatos(state, data) {
      state.cargaDatos = data;
    },
    setCountMensajes(state, data) {
      state.countMensajes = data;
    },
    setNotificacion(state, data) {
      state.notificacion.show = data;
    },
    SOCKET_SET_USUARIO: (state, data) => {
      let dataMap = data.map(item => {return item.user})
      state.usuarios = dataMap.filter(e => e.role != 'SERVIDOR');
    },
    SOCKET_NOTIFICAR: (state, data) => {
      state.notificacion.title = data.title;
      state.notificacion.mensaje = data.mensaje;
      state.notificacion.color = data.color;
      state.notificacion.show = true;
    },
  },

  getters: {
    usuarios: state => {
      return state.usuarios
    },
    drawerRight: state => {
      return state.drawerRight
    },
  },

  actions: {

    SOCKET_addusuario: (context, data) => {
      context.commit("SOCKET_SET_USUARIO", data);
    },
    SOCKET_delusuario: (context, data) => {
      context.commit("SOCKET_SET_USUARIO", data);
    },
    SOCKET_notificar: (context, data) => {
      context.commit("SOCKET_NOTIFICAR", data);
    },
    SOCKET_getusuarios: (context, data) => {
      context.commit("SOCKET_SET_USUARIO", data);
    },
  },
})

