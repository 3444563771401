
export class config {
   constructor() {
    this.listaIPS = [];
  }
  convenciones = [
    { text: 'Exodoncia realizada', img: require('@/assets/EXODONCIA_REALIZADA.png'), value: 'EXODONCIA_REALIZADA', partes: ['diente'] },
    { text: 'Exodoncia indicada', img: require('@/assets/EXODONCIA_INDICADA.png'), value: 'EXODONCIA_INDICADA', partes: ['diente'] },
    { text: 'Endodoncia realizada', img: require('@/assets/ENDODONCIA_REALIZADA.png'), value: 'ENDODONCIA_REALIZADA', partes: ['diente'] },
    { text: 'Endodoncia indicada', img: require('@/assets/ENDODONCIA_INDICADA.png'), value: 'ENDODONCIA_INDICADA', partes: ['diente'] },
    { text: 'Exodoncia quirúrgica indicada', img: require('@/assets/EXODONCIA_QUIRURGICA_IND.png'), value: 'EXODONCIA_QUIRURGICA_IND', partes: ['diente'] },
    { text: 'Exodoncia simple indicada', img: require('@/assets/EXODONCIA_SIMPLE_INDICADA.png'), value: 'EXODONCIA_SIMPLE_IND', partes: ['diente'] },
    { text: 'Procedimiento realizado', img: require('@/assets/PROCEDIMIENTO_REALIZADO.png'), value: 'PROCEDIMIENTO_REALIZADO', partes: ['diente'] },
    { text: 'Diente provisional en buen estado', img: require('@/assets/DIENTE_PROVISIONAL_BUEN_ESTADO.png'), value: 'DIENTE_PROVISIONAL_BUEN_ESTADO', partes: ['diente'] },
    { text: 'Diente provisional en mal estado', img: require('@/assets/DIENTE_PROVISIONAL_MAL_ESTADO.png'), value: 'DIENTE_PROVISIONAL_MAL_ESTADO', partes: ['diente'] },
    { text: 'Diente sin Erupcionar', img: require('@/assets/DIENTE_SIN_ERUPCIONAR.png'), value: 'DIENTE_SIN_ERUPCIONAR', partes: ['diente'] },
    { text: 'En erupcion buen estado', img: require('@/assets/ERUPCION_BUEN_ESTADO.png'), value: 'ERUPCION_BUEN_ESTADO', partes: ['diente'] },
    { text: 'En erupcion mal estado', img: require('@/assets/ERUPCION_MAL_ESTADO.png'), value: 'ERUPCION_MAL_ESTADO', partes: ['diente'] },
    { text: 'Protesis en buen estado', img: require('@/assets/PROTESIS_BUEN_ESTADO.png'), value: 'PROTESIS_BUEN_ESTADO', partes: ['diente'] },
    { text: 'Protesis en mal estado', img: require('@/assets/PROTESIS_MAL_ESTADO.png'), value: 'PROTESIS_MAL_ESTADO', partes: ['diente'] },
    { text: 'Protesis fija en buen estado', img: require('@/assets/PROTESIS_FIJA_BUEN_ESTADO.png'), value: 'PROTESIS_FIJA_BUEN_ESTADO', partes: ['diente'] },
    { text: 'Protesis fija en mal estado', img: require('@/assets/PROTESIS_FIJA_MAL_ESTADO.png'), value: 'PROTESIS_FIJA_MAL_ESTADO', partes: ['diente'] },
    { text: 'Diente sano', img: require('@/assets/DIENTE_SANO.png'), value: 'DIENTE_SANO', partes: ['diente'] },
    { text: 'Corona en buen estado', img: require('@/assets/CORONA_BUEN_ESTADO.png'), value: 'CORONA_BUEN_ESTADO', partes: [ 'parte5'] },
    { text: 'corona en mal estado', img: require('@/assets/CORONA_MAL_ESTADO.png'), value: 'CORONA_MAL_ESTADO', partes: [ 'parte5'] },
    { text: 'Nucleo en buen estado', img: require('@/assets/NUCLEO_BUEN_ESTADO.png'), value: 'NUCLEO_BUEN_ESTADO', partes: [ 'parte5'] },
    { text: 'Nucleo en mal estado', img: require('@/assets/NUCLEO_MAL_ESTADO.png'), value: 'NUCLEO_MAL_ESTADO', partes: [ 'parte5'] },
    { text: 'Sellante indicado', img: require('@/assets/SELLANTE_INDICADO.png'), value: 'SELLANTE_INDICADO', partes: [ 'diente'] },
    { text: 'Sellante presente', img: require('@/assets/SELLANTE_PRESENTE.png'), value: 'SELLANTE_PRESENTE', partes: [ 'diente'] },
    { text: 'erocion arriba', img: require('@/assets/EROCION_ARRIBA.png'), value: 'EROCION_ARRIBA', partes: ['parte6', 'parte7'] },
    { text: 'erocion doble', img: require('@/assets/EROCION_DOBLE.png'), value: 'EROCION_DOBLE', partes: ['diente'] },
    { text: 'erocion abajo', img: require('@/assets/EROCION_ABAJO.png'), value: 'EROCION_ABAJO', partes: ['parte6', 'parte7'] },
    { text: 'fracturado', img: require('@/assets/FRACTURADO.png'), value: 'FRACTURADO', partes: ['parte1', 'parte2', 'parte3', 'parte4', 'parte5', 'parte6', 'parte7'] },
    { text: 'cariado', img: require('@/assets/CARIADO.png'), value: 'CARIADO', partes: ['parte1', 'parte2', 'parte3', 'parte4', 'parte5', 'parte6', 'parte7'] },
    { text: 'obturado amalgama', img: require('@/assets/OBTURADO.png'), value: 'OBTURADO', partes: ['parte1', 'parte2', 'parte3', 'parte4', 'parte5', 'parte6', 'parte7'] },
    { text: 'obturado resina', img: require('@/assets/RESINA.png'), value: 'RESINA', partes: ['parte1', 'parte2', 'parte3', 'parte4', 'parte5', 'parte6', 'parte7'] },
    { text: 'obturado vidrio', img: require('@/assets/VIDRIO.png'), value: 'VIDRIO', partes: ['parte1', 'parte2', 'parte3', 'parte4', 'parte5', 'parte6', 'parte7'] },
    { text: 'obturado temporal', img: require('@/assets/TEMPORAL.png'), value: 'TEMPORAL', partes: ['parte1', 'parte2', 'parte3', 'parte4', 'parte5', 'parte6', 'parte7'] },
    { text: 'resina recurrente', img: require('@/assets/RESINA_RECURRENTE.png'), value: 'RESINA_RECURRENTE', partes: ['parte1', 'parte2', 'parte3', 'parte4', 'parte5', 'parte6', 'parte7'] },
    { text: 'amalgama recurrente', img: require('@/assets/OBTURADO_RECURRENTE.png'), value: 'OBTURADO_RECURRENTE', partes: ['parte1', 'parte2', 'parte3', 'parte4', 'parte5', 'parte6', 'parte7'] },
    { text: 'vidrio recurrente', img: require('@/assets/VIDRIO_RECURRENTE.png'), value: 'VIDRIO_RECURRENTE', partes: ['parte1', 'parte2', 'parte3', 'parte4', 'parte5', 'parte6', 'parte7'] },
  ];
  cargar = async function (){
    this.listaIPS = await fetch('https://sistema.ips-tools.com/ipsList.json').then(response =>  response.json());
  }
}