import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueCookies from 'vue-cookies'
import Vuelidate from "vuelidate";
import VueSocketio from 'vue-socket.io';
import 'chartist/dist/chartist.min.css';
import VueChatScroll from 'vue-chat-scroll';
import apiService from './api.js';
import { config } from './config';
import Filtros from './components/Dfiltros.vue';
import Chat from './components/Chat.vue';
import Menu from './components/Menu.vue';
import widgetMedico from './components/widgetMedico.vue';
import Paginacion from './components/Paginacion.vue';
import Loader from './components/Loader.vue';
import CardTable from './components/CardTable.vue';
import AlertasPaciente from './components/AlertasPaciente.vue';
import axios from 'axios';
import VueSweetalert2 from 'sweetalert2';
import './styles/stylos.sass'
import 'codemirror/lib/codemirror.css'
import io from "socket.io-client";
import VueCodemirror from "vue-codemirror";
Vue.config.productionTip = false
// register globally
Vue.component('Dfiltros', Filtros);
Vue.component('widgetMedico', widgetMedico);
Vue.component('Chat', Chat);
Vue.component('Menu', Menu);
Vue.component('Loader', Loader);
Vue.component('Paginacion', Paginacion);
Vue.component('CardTable',CardTable);
Vue.component('AlertasPaciente',AlertasPaciente);

Vue.config.productionTip = false
Vue.use(VueChatScroll);
Vue.use(require('vue-chartist'));
Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.use(VueCodemirror);
let MODO = 'prod';

let URL = MODO == 'prod' ? 'https://socket.ips-tools.com' : 'http://localhost:5000';
const socket = io(`${URL}`, {
  secure: true,
  extraHeaders: {
    "Access-Control-Allow-Origin": "*"
  }
});
Vue.use(new VueSocketio({
  debug: false,
  connection: socket,
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_"
  },
}));
if (typeof window !== 'undefined') {
  router.beforeEach((to, from, next) => {
    
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    if (!nearestWithMeta) return next();
    if (Vue.$cookies.get('token')== null) {
      Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie));
      return next('/login')
    }
    
    next();
  });
}

Vue.$cookies.config('7d');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.timeout = 7200000;
Vue.prototype.$apiService = new apiService(router, axios, Vue.$cookies);
Vue.prototype.$config = new config();

Vue.prototype.$swal = VueSweetalert2;
if (typeof window !== 'undefined') {
  window.swal = Vue.prototype.$swal;
}
new Vue({
  router,
  store,
  vuetify,
  sockets: {
    connect: function() {
      console.log("socket connected");
      if (this.$cookies.isKey("token")) {
        if (this.$cookies.get("token") != undefined) {
          let user = this.$cookies.get("user");
          this.$socket.emit("addusuario", {
            user:user,
            token:this.$cookies.get("token")
          });
          this.$socket.emit("getusuarios", {
            user:user,
            token:this.$cookies.get("token")
        });
        }
      }
    },
    reconnect: function() {
      console.log("socket reconnected");
      if (this.$cookies.isKey("token")) {
        if (this.$cookies.get("token") != undefined) {
          let user = this.$cookies.get("user");
          this.$socket.emit("addusuario", {
            user:user,
            token:this.$cookies.get("token")
          });
          this.$socket.emit("getusuarios", {
            user:user,
            token:this.$cookies.get("token")
        });
        }
      }
    },
    disconnect: function() {
      console.log("socket desconnected");
      if (this.$cookies.isKey("token")) {
        if (this.$cookies.get("token") != undefined) {

          let user = this.$cookies.get("user");
          this.$socket.emit("delusuario", {
            user:user
          });
        }
      }
    }
  },
  destroyed() {
    delete this.$apiService;
    delete this.$swal;
    delete this.$config;
  },
  render: h => h(App)
}).$mount('#app')





