<template lang="">
<v-row  class="mt-2 pb-5" align="center" justify="center">
    <span class="mr-4 grey--text">
        Pagina {{ propiedades.page }} de {{ propiedades.pageCount }}, Total resultados: {{(propiedades.Count)}}
    </span>
    <v-pagination  color="primary" circle v-model="propiedades.page" @input="$emit('cambiarPagina',propiedades.page);onSearch(propiedades.filtros)" @next="$emit('cambiarPagina',propiedades.page);onSearch(propiedades.filtros)" @previous="$emit('cambiarPagina',propiedades.page);onSearch(propiedades.filtros)" :total-visible="6" :length="propiedades.pageCount"></v-pagination>

</v-row>
</template>

<script>
export default {
    name: 'Paginacion',
    props: {
        propiedades: Object,
        onSearch: Function
    },
}
</script>