import swal from "sweetalert2";
import jwtDecode from "jwt-decode";
const api = function (router, axios, session) {
    this.router = router;
    this.axios = axios;
    this.session = session;
    this.token = '';
    let MODO = 'prod';
    let URL = MODO == 'prod' ? 'https://api.ips-tools.com' : 'http://localhost:4000';
    this.axios.defaults.baseURL = `${URL}/api/v1/`;
};
api.prototype.setConfigApi= function(token = ''){
    this.token = token;
};
api.prototype.verificarlogin = async function() {
    return await this.axios.get("auth/verifyauth", {
        headers: {
            token: this.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 0
        }

    }).then(response => response.data).catch(err => err);
};
api.prototype.login = async function (form) {
    return await this.axios.post("auth/login", form).then(response => response.data).catch(err => err);
};
api.prototype.usercan = async function ( auth_item_child) {
    let decoded = jwtDecode(this.token);
    if (decoded.status) {
        let can = await this.axios.post("auth/usercan", auth_item_child, {
            headers: {
                token: this.token,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Max-Age": 0
            }
        }).then(data => data).catch(error => {
            console.log(error);
            this.session.keys().forEach(cookie => this.session.remove(cookie));
            this.router.push({ path: '/login'});
        });
        if (can) {
            if (can.data == null) {
                this.router.push('/403');
            } else {
                if (can.data.message == "no autorizado") {
                    this.session.keys().forEach(cookie => this.session.remove(cookie));
                    this.router.push({ path: '/login' });
                }
                return true;
            }
        } else {
            this.session.keys().forEach(cookie => this.session.remove(cookie));
            this.router.push({ path: '/login' });
        }
    } else {
        this.session.keys().forEach(cookie => this.session.remove(cookie));
        this.router.push({ path: '/login' });
    }
};
api.prototype.usercan2 = async function ( auth_item_child) {
    
        let can = await this.axios.post("auth/usercan", auth_item_child, {
            headers: {
                token: this.token,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Max-Age": 0
            }
        }).then(data => data).catch(error => {
            console.log(error);
            this.session.keys().forEach(cookie => this.session.remove(cookie));
            this.router.push({ path: '/login'});
        });
        if (can) {
            if (can.data == null) {
                return false;
            } else {
                if (can.data.message == "no autorizado") {
                    return false;
                }
                return true;
            }
        } else {
            return false;
        }
};
api.prototype.usermenu = async function ( auth_item_parent) {
    let can = await this.axios.post("auth/usermenu", auth_item_parent, {
        headers: {
            token: this.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 0
        }
    }).then(data => data).catch(error => {
        console.log(error.response.status);
        this.session.keys().forEach(cookie => this.session.remove(cookie));
        this.router.push('/login');
    });
    if (can) {
        return await can.data;
    } else {
        return false
    }
};
api.prototype.indexAll = async function (urls) {
    return await this.axios.all(urls).catch(error => {
        console.log(error);
        swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
        });
    });
};
api.prototype.index = async function (url) {
    return await this.axios.get(url, {
        headers: {
            token: this.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 0
        }
    }).then(response => response.data).catch(error => {
        console.log(error.response.status);
        if(error.response.status== 404){
            this.session.keys().forEach(cookie => this.session.remove(cookie));
            this.router.push('/login');
            swal.fire({
                title: "Sesion caducada!",
                text: "Inicie sesion nueva mente",
                icon: "warning"
            });
        }else{
            swal.fire({
                title: "Algo sucedio!",
                text: "contacta con el administrador",
                icon: "error"
            });
        }
    });
};
api.prototype.getFile = async function (url) {

    return await this.axios.get(url, {
        headers: {
            token: this.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 0
        },
        responseType: 'arraybuffer'
    }).then(response => response.data).catch(error => {
        console.log(error);
        swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
        });
    });
};
api.prototype.view = async function (url) {
    return await this.axios.get(url, {
        headers: {
            token: this.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 0
        }
    }).then(response => response.data).catch(error => {
        console.log(error);
        swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
        });
    });
};
api.prototype.create = async function (url, form) {
    return await this.axios.post(url, form, {
        headers: {
            token: this.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 0,
        }
     }).then(response => response.data);
};
api.prototype.createFile = async function (url, form) {
    return await this.axios.post(url, form, {
        headers: {
            token: this.token,
            "Content-Type": "multipart/form-data; charset=utf-8;",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 0,
            "Access-Control-Allow-Headers":"*"
        },
        /*transformRequest: [function (data) {
            return data
        }],*/
    }).then(response => response.data);
};
api.prototype.updateFile = async function (url, form) {
    return await this.axios.put(url, form, {
        headers: {
            token: this.token,
            "Content-Type": "multipart/form-data; charset=utf-8;",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 0,
            "Access-Control-Allow-Headers":"*"
        }
    }).then(response => response.data);
};
api.prototype.update = async function (url, form) {
    return await this.axios.put(url, form, {
        headers: {
            token: this.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 0,
        }
    }).then(response => response.data);
};
api.prototype.delete = async function (url) {
    return await this.axios.delete(url, {
        headers: {
            token: this.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 0
        }
    }).then(response => response.data);
};
export default api;