<template>
    <v-container class="pt-0" v-if="table">
        <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
            <v-col v-for="item in items" :key="item[tabla.itemKey]" cols="12" sm="12" md="12" lg="12">
                <v-card elevation="0" class="rounded-xl">
                    <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                        <v-row no-gutters>
                            <v-col cols="1" sm="1" md="1" lg="1">
                                <v-checkbox  v-if="tabla.isCheck" v-model="seleccionados" :value="item[tabla.itemKey]" @change="onGetSelects(seleccionados)"></v-checkbox>
                            </v-col>
                            <v-col cols="9" sm="9" md="9" lg="9">
                                <v-row no-gutters>
                                    <template v-for="(p,i) in tabla.primarios">
                                        <v-col v-if="p.show(item)" class="subtitle-2" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                            {{p.titulo}} <span :class="p.class">
                                                {{ p.valor(item) }}
                                            </span>
                                        </v-col>
                                    </template>
                                </v-row>
                            </v-col>
                            <v-col cols="2" sm="2" md="2" lg="2">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon outlined v-bind="attrs" v-on="on">
                                            <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <template v-for="(m, i) in tabla.menu">
                                            <div v-if="m.show(item)" :key="i">
                                                <v-list-item v-if="m.tipeAction=='link'" :to="m.action(item)">
                                                    <v-list-item-icon>
                                                        <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item v-else  @click="m.action(item)">
                                                    <v-list-item-icon>
                                                        <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                </v-list-item>
                                            </div>
                                        </template>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row no-gutters>
                            <template v-for="(p,i) in tabla.segundarios">
                                <v-col v-if="p.show(item)" class="caption" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                    {{p.titulo}} <span :class="p.class">
                                        {{ p.valor(item) }}
                                    </span>
                                </v-col>
                            </template>
                            <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                <v-row>
                                    <v-col cols="2" sm="2" md="1" lg="1">
                                        <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                            <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                            <v-icon v-else>mdi-chevron-up</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="10" sm="10" md="11" lg="11">
                                        <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <template v-if="tabla.expandible">
                        <v-divider v-if="isExpanded(item)"></v-divider>
                        <v-card-text v-if="isExpanded(item)">
                            <v-container>
                                    <v-sheet v-for="items in tabla.expandibleItems(item) " min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                        <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`">
                                                <v-col  v-for="(p,i) in tabla.expanItem" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                    {{p.titulo}} <span :class="p.class">
                                                        {{ p.valor(items) }}
                                                    </span>
                                                </v-col>
                                            <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                <v-menu bottom left>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon outlined v-bind="attrs" v-on="on">
                                                            <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <template v-for="(m, i) in tabla.expanMenu">
                                                            <div v-if="m.show(items)" :key="i" >
                                                                <v-list-item v-if="m.tipeAction=='link'" :to="m.action(items)">
                                                                    <v-list-item-icon>
                                                                        <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-else @click="m.action(items)">
                                                                    <v-list-item-icon>
                                                                        <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                </v-list-item>
                                                            </div>
                                                        </template>
                                                    </v-list>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                            </v-container>
                        </v-card-text>
                    </template>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  props: {
    table: Object,
    onGetSelects: Function,
  },
  data() {
    return {
     seleccionados:[],
     tabla:null,
     items:[], 
     isExpanded:Function, 
     expand:false
    }
  },
  mounted(){
    this.tabla = this.table.tabla;
    this.items = this.table.data.items;
    this.isExpanded = this.table.data.isExpanded;
    this.expand = this.table.data.expand;
  },
  methods(){
  }
}
</script>
