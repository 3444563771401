import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import minifyTheme from 'minify-css-string';
import es from 'vuetify/es5/locale/es';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);


const opts = {
  theme: {
      themes: {
          light: {
              primary: colors.cyan.darken1,
            //   secondary: colors.purple.lighten2,
            //   accent: colors.blueGrey.darken3,
            //   error: colors.red.darken4,
            //   info: '#2196F3',
            //   success: '#4CAF50',
            //   warning: '#FFC107',
          },
          dark: {
              primary: colors.cyan.darken1,
            //   secondary: colors.purple.lighten2,
            //   accent: colors.blueGrey.lighten2,
            //   error: colors.red.lighten2,
            //   info: '#2196F3',
            //   success: '#4CAF50',
            //   warning: '#FFC107',
          },
      },
      options: {
          minifyTheme ,
          customProperties: true,
      },

  },
  lang: {
      locales: { es },
      current: 'es',
  },
  icons: {
      iconfont: 'md',

  },
}

export default new Vuetify(opts)