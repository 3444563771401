<template>
 <div class="col-md-12 white" v-if="alertas.length > 0">
  <v-alert text prominent type="warning" border="left">
    <span class="headline">ALERTAS DEL PACIENTE</span>
    <br>
        <template v-if="alertasR.length > 0" >
           <strong>Rutas activas:</strong> 
            <div class="pl-5" v-for="(item ) in alertasR" :key="item.cirpId">-{{item.ruta ? item.ruta.comaNombrelargo:''}}</div>
        </template>
        <template v-if="alertasP.length > 0" >
              <strong>Patologias:</strong> 
              <div class="pl-5" v-for="(item ) in alertasP" :key="item.cirpId">-{{item.ruta ? item.ruta.comaNombrelargo:''}}</div>
        </template>
        <template v-if="alertasD.length > 0" >
              <strong>Eventos de salud:</strong> 
              <div class="pl-5" v-for="(item ) in alertasD" :key="item.cirpId">-{{item.ruta ? item.ruta.comaNombrelargo:''}} </div>
        </template>
  </v-alert>
</div>
</template>

<script>
import generales from '../mixins/generales.js';
export default {
 name: "AlertasPaciente",
 props: {
   coppId: Number,
 },
 mixins: [generales],
 data() {
   return {
     url: "cita/demanda/",
     alertas: [],
     alertasR: [],
     alertasP: [],
     alertasD: []
   };
 },
 created: async function () {
   this.alertas = await this.$apiService.index(`cita/demanda/index?coppId=${this.coppId}`).then(data => data);
   this.alertasR= this.alertas.filter(e => e.cirpTipo=='RUTA');
   this.alertasP= this.alertas.filter(e => e.cirpTipo=='PATOLOGIA');
   this.alertasD= this.alertas.filter(e => e.cirpTipo=='EVENTO SALUD');
 },
 methods: {
 },
};

</script>

<style></style>
