<template>
<div>

    <v-container lighten-5 v-if="filtros.length>0">
        <v-row>
            <v-col v-for="(filtro, index) in filtros" cols="12" sm="3" md="3" :key="filtro.index">
                <template v-if="filtro.tipo == 'text'">
                    <template v-if="filtro.search">
                        <v-text-field v-model="filtro.value" filled rounded dense :label="filtro.text" v-on:keyup="filtro.value = $event.target.value.toUpperCase()" :loading="filtro.isLoading" single-line hide-details @keypress.enter="onBuscar(filtros)"></v-text-field>
                    </template>
                    <template v-else>
                        <v-text-field v-model="filtro.value" filled rounded dense :label="filtro.text" v-on:keyup="filtro.value = $event.target.value.toUpperCase()" single-line hide-details @keyup="onBuscar(filtros)"></v-text-field>
                    </template>

                </template>

                <template v-if="!filtro.searchNormal && filtro.buscar && filtro.tipo == 'lista'">
                    <v-autocomplete v-model="filtro.value" filled rounded single-line hide-details dense :items="filtro.lista" :item-text="filtro.listatext" :loading="filtro.isLoading" :search-input.sync="filtro.search" @keypress.enter="searchs(filtro.buscar,index)" hide-no-data hide-selected no-filter :item-value="filtro.listavalue" item-color="primary" :label="filtro.text" @change="onBuscar(filtros)" clearable>
                        <template v-slot:selection="data" v-if="filtro.default">
                            {{filtro.defaultselect(data)}}
                        </template>
                        <template v-slot:item="data" v-if="filtro.default">
                            <template v-if="(typeof data.item !== 'object')">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-content>
                                    <v-list-item-title>{{filtro.defaultbody(data).title}}</v-list-item-title>
                                    <v-list-item-subtitle> {{filtro.defaultbody(data).subtitle}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </template>
                <template v-if="!filtro.searchNormal && filtro.filter && filtro.tipo == 'lista'">
                    <v-autocomplete v-model="filtro.value" filled rounded single-line hide-details dense :items="filtro.lista" :filter="filtro.filter" :item-text="filtro.listatext" :item-value="filtro.listavalue" item-color="primary" :label="filtro.text" @change="onBuscar(filtros)" clearable>
                        <template v-slot:selection="data" v-if="filtro.default">
                            {{filtro.defaultselect(data)}}
                        </template>
                        <template v-slot:item="data" v-if="filtro.default">
                            <template v-if="(typeof data.item !== 'object')">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-content>
                                    <v-list-item-title>{{filtro.defaultbody(data).title}}</v-list-item-title>
                                    <v-list-item-subtitle> {{filtro.defaultbody(data).subtitle}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </template>
                <template v-if="!filtro.buscar && !filtro.searchNormal && !filtro.filter && filtro.tipo == 'lista'">
                    <v-autocomplete v-model="filtro.value" filled rounded single-line hide-details dense :items="filtro.lista" :item-text="filtro.listatext" :item-value="filtro.listavalue" item-color="primary" :label="filtro.text" @change="onBuscar(filtros)" clearable>
                        <template v-slot:selection="data" v-if="filtro.default">
                            {{filtro.defaultselect(data)}}
                        </template>
                        <template v-slot:item="data" v-if="filtro.default">
                            <template v-if="(typeof data.item !== 'object')">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-content>
                                    <v-list-item-title>{{filtro.defaultbody(data).title}}</v-list-item-title>
                                    <v-list-item-subtitle> {{filtro.defaultbody(data).subtitle}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>

                </template>
                <template v-if="!filtro.buscar  && filtro.searchNormal && filtro.tipo == 'lista'">
                    <v-autocomplete v-model="filtro.value" filled rounded single-line hide-details dense :items="filtro.lista" :item-text="filtro.listatext" :loading="filtro.isLoading" :item-value="filtro.listavalue" item-color="primary" :label="filtro.text" @change="onBuscar(filtros)" clearable>
                        <template v-slot:selection="data" v-if="filtro.default">
                            {{filtro.defaultselect(data)}}
                        </template>
                        <template v-slot:item="data" v-if="filtro.default">
                            <template v-if="(typeof data.item !== 'object')">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-content>
                                    <v-list-item-title>{{filtro.defaultbody(data).title}}</v-list-item-title>
                                    <v-list-item-subtitle> {{filtro.defaultbody(data).subtitle}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </template>

                <template v-if="!filtro.searchNormal &&filtro.tipo == 'fecha'">
                    <v-menu v-model="filtro.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="filtro.value" filled rounded single-line hide-details dense :label="filtro.text" append-icon="event" @input="onBuscar(filtros)" readonly v-on="on" clearable></v-text-field>
                        </template>
                        <v-date-picker locale="ES" v-model="filtro.value" @change="onBuscar(filtros)" @input="filtro.menu = false"></v-date-picker>
                    </v-menu>
                </template>
                <template v-if="filtro.searchNormal && filtro.tipo == 'fecha'">
                    <v-menu v-model="filtro.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="filtro.value" filled rounded single-line hide-details dense :label="filtro.text" append-icon="event" @input="onBuscar(filtros)" readonly v-on="on" clearable></v-text-field>
                        </template>
                        <v-date-picker locale="ES" v-model="filtro.value" :loading="filtro.isLoading" @change="onBuscar(filtros)" @input="filtro.menu = false"></v-date-picker>
                    </v-menu>
                </template>
                <template v-if="filtro.tipo == 'rango'">
                    <v-menu v-model="filtro.menu" :ref="`menu${filtro.text}`" :return-value.sync="filtro.value" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="filtro.value" filled rounded single-line hide-details dense :label="filtro.text" append-icon="event" readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker range locale="ES" v-model="filtro.value" :loading="filtro.isLoading">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="filtro.menu = false;filtro.value = [];$refs[`menu${filtro.text}`][0].save(filtro.value);onBuscar(filtros)">Cancel</v-btn>

                            <v-btn text color="primary" @click="$refs[`menu${filtro.text}`][0].save(filtro.value);(filtro.value.length> 1)?onBuscar(filtros):false">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </template>

            </v-col>

        </v-row>
    </v-container>
</div>
</template>

<script>


export default {
    name: 'Dfiltros',
    props: {
        onBuscar: Function,
        filtros: Array,
        envFiltros: Function,
    },
    data() {
        return {
            token: this.$cookies.get("token"),
            
        }
    },
    created: async function () {

        
    },
    methods: {
        async searchs(tipo, index) {
            switch (tipo) {
                case 'paciente':
                    if (this.filtros[index].search.length > 0 && this.filtros[index].search.toString().replace(/ /g, "") != "") {
                        this.filtros[index].isLoading = true;

                        this.$apiService.index("sistema/personas/buscar2/pacientes/" + this.filtros[index].search.toUpperCase()).then(data => {
                            this.filtros[index].isLoading = false;
                            this.filtros[index].lista = data;

                        })
                    }
                    break;
                case 'maestra':
                    if (this.filtros[index].search.length > 0 && this.filtros[index].search.toString().replace(/ /g, "") != "") {
                        this.filtros[index].isLoading = true;

                        this.$apiService.index("sistema/maestra/buscar/" + this.filtros[index].search.toUpperCase()).then(data => {
                            this.filtros[index].isLoading = false;
                            this.filtros[index].lista = data;

                        })
                    }
                    break;
                case 'cup':
                    if (this.filtros[index].search.length > 0 && this.filtros[index].search.toString().replace(/ /g, "") != "") {
                        this.filtros[index].isLoading = true;

                        this.$apiService.index("sistema/cups/buscar/" + this.filtros[index].search.toUpperCase()).then(data => {
                            this.filtros[index].isLoading = false;
                            this.filtros[index].lista = data;

                        })
                    }
                    break;

            }
        },
        /* vaciar(tipo, index) {
             switch (tipo) {
                 case 'paciente':
                     this.filtros[index].lista = [];

                     break;

             }
         }*/
    },
}
</script>

<style lang="css" scoped>
</style>
<!-- 

-->
