<style lang="css" scoped>

</style>

<template>
<v-card class="mx-auto" min-height="400" max-height="400">
    <div>
        <v-toolbar color="primary darken-3" dark>

            <v-toolbar-title>Atenciones para hoy</v-toolbar-title>

            <v-spacer></v-spacer>

        </v-toolbar>
        <template v-if="!cargando">
            <template v-if="!(items.length > 0)">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-alert text prominent dense type="info">
                                No tienes citas para hoy
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>

            </template>
            <template v-else>
                <v-list two-line>
                    <v-list-item-group>
                        <template v-for="(item, index) in items">
                            <v-list-item :key="item.citaId" @click="$emit('cerrarWidget',false)" :to="{ name: 'Createhistoria',params: { id: item.tblCitaFacturaciones[0].cifaId,servicio:item.tblConfContratosxservicios[0].coseId ,  paciente:item.tblConfPersonaspacientes[0]} }">
                                <template v-slot:default="{  }">
                                    <v-list-item-content class="text-left">
                                        <v-list-item-title class="primary--text" v-text="(item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + ' ' + item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre2 + ' ' + item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 + ' ' + item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido2)"></v-list-item-title>
                                        <v-list-item-subtitle v-text="item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-text="item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre"></v-list-item-subtitle>
                                     <v-list-item-subtitle v-text="item.citaHora"></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <template v-if="item.tblCitaFacturaciones[0].cifaTipoTurno">
                                            <v-list-item-action-text class="green--text" v-text="item.tblCitaFacturaciones[0].cifaTipoTurno"></v-list-item-action-text>
                                       </template>
                                    <template v-if="item.turno">
                                            <v-list-item-action-text class="green--text" v-text="item.cifaTipoTurno"></v-list-item-action-text>
                                    </template>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>

                            <v-divider v-if="index + 1 < items.length" :key="index"></v-divider>
                        </template>

                    </v-list-item-group>
                </v-list>
            </template>
        </template>
        <template v-else>
            <v-col cols="12" sm="12" md="12" class="text-center">
                <span class="headline">CARGANDO CITAS....</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="text-center">
                <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
            </v-col>
        </template>
    </div>
</v-card>
</template>

<script>

export default {
    name: 'widgetMedico',
    data() {
        return {
            cargando: true,
            items: [],
            tipoturno: [],
            url: "cita/asistenciales/",
            user: this.$cookies.get("user"),
            token: this.$cookies.get("token"),
            
        };
    },
    created: async function () {
        
        if (!this.$cookies.isKey("token") || !this.$cookies.get("token")) {
            this.$cookies.remove('token'); this.$cookies.remove('user');
            this.$router.push({ path: '/', params: { login: true } });
        }
        //active, toggle
        if (!(this.$cookies.get("user").role.indexOf('ADMIN') > -1 || this.$cookies.get("user").role.indexOf('Administrador (IPS)') > -1 || this.$cookies.get("user").role.indexOf('ENFERMERA FULL') > -1)) {
            this.items = await this.$apiService.index("cita/asistenciales/" + "index/" + this.$cookies.get("user").empleado + "/" + new Date().yyyymmdd()).then(data => data);
        } else {
            this.items = await this.$apiService.index("cita/asistenciales/" + "index/dia/" + new Date().yyyymmdd()).then(data => data);
        }
        if (this.items.length > 0) {
            this.items = this.items.filter(e => e.estado.comaNombrecorto != 'ASISTIDA' && e.estado.comaNombrecorto == 'FACTURADA');
            this.items.sort((a, b) =>  new Date('2021/01/01 ' + a.citaHora).getTime() - new Date('2021/01/01 ' + b.citaHora).getTime() );
        }

        this.cargando = false;
    },

    
    methods: {

    }
}
</script>
