<template>
    <v-navigation-drawer id="menu-der" hide-overlay v-model="drawerRight" width="300px" right app>
        <v-card height="100%" style="overflow:hidden" flat color="basil" v-if="converzacionActiva">
            <v-card-title>
                <v-btn text icon @click="cerrarConverzacion">
                    <v-icon large>
                        mdi-close
                    </v-icon>
                </v-btn>
                <span style="width:80%; display:inline-block" class="title text-center " v-if="userActivo">{{userActivo}}
                    <v-icon :color="((activo)?'green':'red') +' lighten-1'">mdi-circle-medium</v-icon>
                </span>
            </v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text id="scroll-converzacion" v-scroll:#scroll-converzacion="onScroll" v-chat-scroll v-if="!loading">
                <template v-for="(item , i) in converzacion">

                    <v-sheet :class="'d-flex ' + ((user.username == item.chmeUsuario)?'justify-start':'float-right') +' mb-5 ml-5 mr-5 '" :color="(user.username == item.chmeUsuario)?'blue':'grey' + ' lighten-3'" min-height="50px" width="200px" :key="i">
                        <v-badge :value="false" style="width:100% ;" :color="(user.username == item.chmeUsuario)?'grey lighten-4':'light-blue lighten-5'" bottom :left="((user.username == item.chmeUsuario)?true:false)">
                            <span style="width:100%; display:inline-block" :class="(user.username == item.chmeUsuario)?'white--text pl-2 pt-2':'black--text text-right pr-2 pt-2'">{{item.chmeMensaje}}
                                <p :style="(user.username == item.chmeUsuario)?'background-color: #e6e6e6a8; width: 160px;padding-left: 3px; border-radius: 10px; bottom: calc(0% - 25px); position: absolute;left: calc(0% - 10px);':'background-color: rgba(5, 119, 193, 0.66); width: 160px;padding-right: 3px;border-radius: 10px;bottom: calc(0% - 25px);position: absolute;right: calc(0% - 10px);'" :class="(user.username == item.chmeUsuario)?'black--text caption':'white--text caption'">{{new Date(item.chmeFecha).toLocaleString('es-ES',{hour12:true}) }}</p>
                            </span>
                        </v-badge>
                    </v-sheet>

                </template>
            </v-card-text>
            <v-card-text id="scroll-converzacion" v-else>
                <v-col cols="12" sm="12" md="12" class="text-center">
                    <span class="headline">cargando conversacion</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="text-center">
                    <v-progress-circular :width="7" :size="100" color="primary" indeterminate></v-progress-circular>
                </v-col>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-actions id="scroll-actions" class="pb-0 mb-0">
                <v-text-field v-model="mensaje" color="basil" append-icon="mdi-send" filled rounded dense label="Escribe algo" @keypress.enter="enviarMensaje" @click:append="enviarMensaje"></v-text-field>
            </v-card-actions>
        </v-card>
        <v-tabs v-model="tab" background-color="transparent" color="basil" grow v-else>
            <v-tab href="#tab-1" @click="refrescarUsuarios()">
                Contactos
            </v-tab>
            <v-tab href="#tab-2">
                Conversaciones
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" v-if="!converzacionActiva">
            <v-tab-item value="tab-1">
                <v-card flat color="basil">
                    <v-card-text>
                        <v-list dense>
                            <template v-for="(item, i) in getUsuarios.filter(e=>(e.username != user.username && user.role.indexOf('PACIENTE') == -1 ) || (e.username != user.username && user.role.indexOf('PACIENTE') > -1  &&  e.user.role.indexOf('PACIENTE') == -1))">
                                <div :key="i">
                                    <v-divider></v-divider>
                                    <v-list-item @click="iniConverzacion(item.username)">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="(item.nombre)?item.nombre:item.username"></v-list-item-title>
                                            <v-list-item-subtitle v-text="item.role"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn small icon>
                                                <v-icon color="green lighten-1">mdi-circle-medium</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </div>

                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2">
                <v-card flat color="basil">
                    <v-card-text>
                        <v-list dense>
                            <template v-for="(item, i) in converzaciones">
                                <div :key="i" v-if="item.tblChatMensajes.length>0">
                                    <v-divider></v-divider>
                                    <v-list-item :class="(item.countNoVistos>0)?'blue lighten-5':''">

                                        <v-list-item-content style="cursor:pointer;" @click="iniConverzacion(item.chchUsuario2)">

                                            <v-list-item-title>{{(item.usuario2 && item.usuario2.tblConfPersona)?(item.usuario2.tblConfPersona.tblConfPersonasnaturales[0].copnNombre1 +" "+item.usuario2.tblConfPersona.tblConfPersonasnaturales[0].copnApellido1):item.chchUsuario2}} <span class="red--text">{{item.countNoVistos}}</span>
                                                <v-icon :color="( (getUsuarios.filter(e=> e.username == item.chchUsuario2).length > 0)?'green':'red' )+ ' lighten-1'">mdi-circle-medium</v-icon>
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-text="item.tblChatMensajes[0].chmeMensaje"></v-list-item-subtitle>
                                            <v-list-item-subtitle>

                                                {{new Date(item.tblChatMensajes[0].chmeFecha).toLocaleString('es-ES',{hour12:true})}}
                                            </v-list-item-subtitle>

                                        </v-list-item-content>
                                        <v-list-item-action>

                                            <v-btn small icon @click="eliminarConverzacion(item.chchId)">
                                                <v-icon color="red lighten-1">mdi-delete-empty</v-icon>
                                            </v-btn>

                                        </v-list-item-action>

                                    </v-list-item>
                                </div>

                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-navigation-drawer>
</template>

<script>
import {
  mapState
} from 'vuex';
export default {
  name: 'chat',

  data() {
    return {
      tab: 'tab-1',
      loading: false,
      converzacionActiva: false,
      converzacion: [],
      converzaciones: [],
      userActivo: null,
      activo: false,
      mensaje: '',
      chat: null,
      token: this.$cookies.get("token"),

      user: this.$cookies.get("user"),
      url: 'chats/'
    }
  },
  computed: {

    ...mapState({
      countMensajes: 'countMensajes',

    }),
    getUsuarios() {
      return this.$store.getters.usuarios
    },
    drawerRight: {
      get() {
        return this.$store.getters.drawerRight
      },
      set(newName) {
        return newName
      }
    }

  },
  created: async function() {
    this.refrescarUsuarios()
    const data = await this.$apiService.index(this.url + `index/${this.$cookies.get("user").username}?offset=0`).then(data => data);
    if (data) {
      this.converzaciones = data.rows;
      for (let converza of this.converzaciones) {
        this.$store.commit('setCountMensajes', this.countMensajes + converza.countNoVistos);
      }
    }
    if (this.$cookies.isKey("token")) {
      if (this.$cookies.get("user") != undefined) {
        // this.$socket.emit('addusuario', this.$cookies.get("user"));
      }
    }
  },
  sockets: {
    connect: function() {
      if (this.getUsuarios.filter(e => e.username == this.userActivo).length > 0) {
        this.activo = true;
      }
    },
    sendmensaje: async function(data) {
      if (data.chmeUsuario == this.userActivo) {
        this.converzacion.push(data);
      } else {
        this.$store.commit('setCountMensajes', this.countMensajes + 1);
        if (this.converzaciones.find(e => e.chchUsuario2 == data.chmeUsuario)) {
          this.converzaciones.find(e => e.chchUsuario2 == data.chmeUsuario).tblChatMensajes.push(data);
          this.converzaciones.find(e => e.chchUsuario2 == data.chmeUsuario).countNoVistos = this.converzaciones.find(e => e.chchUsuario2 == data.chmeUsuario).countNoVistos + 1;
        }else{
            const data = await this.$apiService.index(this.url + `index/${this.$cookies.get("user").username}?offset=0`).then(data => data);
            if (data) {
                this.converzaciones = data.rows;
            }
        }
      }

    },
    disconnect: function() {
      if (this.getUsuarios.filter(e => e.username == this.userActivo).length > 0) {
        this.activo = true;
      } else {
        this.activo = false;
      }
    }
  },
  methods: {
    async iniConverzacion(user) {
      this.converzacionActiva = true;
      this.userActivo = user;
      if (this.getUsuarios.filter(e => e.username == this.userActivo).length > 0) {
        this.activo = true;
      }
      this.loading = true;
      this.chat = await this.$apiService.create(this.url + `createchat`, {
        chchUsuario1: this.user.username,
        chchUsuario2: this.userActivo
      }).then(data => data);
      this.loading = false;
      if (this.chat) {
        const mensajes = await this.$apiService.index(this.url + `converzacion/${this.chat.chat1.chchId}?offset=0`).then(data => data);
        this.converzacion = mensajes.rows[0].tblChatMensajes;
        this.converzacion.sort((a, b) => a.chmeId - b.chmeId);

        if (this.converzaciones.filter(e => e.chchUsuario2 == this.userActivo).length > 0) {
          this.$store.commit('setCountMensajes', this.countMensajes - this.converzaciones.filter(e => e.chchUsuario2 == this.userActivo)[0].countNoVistos);
          this.converzaciones.filter(e => e.chchUsuario2 == this.userActivo)[0].countNoVistos = 0;
        }
        this.$apiService.update(this.url + `visto/${this.chat.chat1.chchId}/${this.chat.chat1.chchUsuario2}`, null).then(data => data);
        this.$apiService.update(this.url + `visto/${this.chat.chat2.chchId}/${this.chat.chat2.chchUsuario1}`, null).then(data => data);
      }

    },
    async enviarMensaje() {
      const mensaje = [{
        chmeFecha: new Date(),
        chmeUsuario: this.user.username,
        chmeMensaje: this.mensaje,
        chchId: this.chat.chat1.chchId
      }, {
        chmeFecha: new Date(),
        chmeUsuario: this.user.username,
        chmeMensaje: this.mensaje,
        chchId: this.chat.chat2.chchId
      }];
      const enviado = await this.$apiService.create(this.url + "enviar/", mensaje).then(data => data);

      if (enviado) {
        this.converzacion.push(enviado[0]);
        if (this.converzaciones.find(e => e.chchUsuario2 == this.userActivo)) {
          this.converzaciones.find(e => e.chchUsuario2 == this.userActivo).tblChatMensajes.push(enviado[0]);
        } else {
          const data = await this.$apiService.index(this.url + `index/${this.$cookies.get("user").username}?offset=0`).then(data => data);
          if (data) {
            this.converzaciones = data.rows;
            for (let converza of this.converzaciones) {
              this.$store.commit('setCountMensajes', this.countMensajes + converza.countNoVistos);
            }
          }
        }

        if (this.getUsuarios.filter(e => e.username == this.userActivo).length > 0) {
          this.$socket.emit('sendmensaje', {
            username: this.userActivo,
            mensaje: enviado[1],
            version: this.user.version
          });
        }
        this.mensaje = '';

        this.$apiService.update(this.url + `visto/${this.chat.chat1.chchId}/${this.chat.chat1.chchUsuario2}`, null).then(data => data);
        this.$apiService.update(this.url + `visto/${this.chat.chat2.chchId}/${this.chat.chat2.chchUsuario1}`, null).then(data => data);

      }

    },
    async onScroll(e) {
      if (e.target.scrollTop == 0) {
        if (this.chat) {
          const mensajes = await this.$apiService.index(this.url + `converzacion/${this.chat.chat1.chchId}?offset=${this.converzacion.length-1}`).then(data => data);
          this.converzacion = this.converzacion.concat(mensajes.rows[0].tblChatMensajes);
          this.converzacion.sort((a, b) => a.chmeId - b.chmeId);

        }
      }
    },
    cerrarConverzacion() {
      this.userActivo = null;
      this.converzacionActiva = false;
      this.activo = false;
      this.converzacion = null;
    },
    async eliminarConverzacion(id) {
      try {

        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id).then(data => data);
          if (data) {
            this.converzaciones = this.converzaciones.filter(
              e => e.chchId != id
            );
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }

        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    refrescarUsuarios(){
      let user = this.$cookies.get("user");
        this.$socket.emit("getusuarios", {
            user:user,
            token:this.$cookies.get("token")
        });
    }
  },
}

</script>

<style scoped>
#scroll-converzacion {
  height: 78%;
  overflow: auto;
}

#scroll-actions {
  height: 10%;
}

@media (min-height: 1000px) {
  #scroll-converzacion {
    height: 83% !important;
    overflow: auto;
    margin-top: 6px;
  }

  #scroll-actions {
    height: 10%;
  }
}

@media (max-height: 700px) {
  #scroll-converzacion {
    height: 70% !important;
    overflow: auto;
    margin-top: 6px;
  }

  #scroll-actions {
    height: 15%;
  }
}

</style>
