export default {

    data() {
        return {
            tab: 'tab-1',
            verFiltros: true,
            dialog3: false,
            dialog2: false,
            dialog: false,
            dialogMasivo: false,
            isNew: true,
            Count: 0,
            pag:1,
            loading:false,
            page: 1,
            pageCount: 0,
            nameRules: [v => !!v || "Campo requirido"],
            token:this.$cookies.get("token"),
            user: this.$cookies.get("user"),
            permisos: JSON.parse(localStorage.getItem("permisos")),
            loadingGuardar: false,
            loadingTable: true,
            LoadingExp: false,
            loadingForm: false,
            loadingPdf: false,
            loadingPanel: false,
            file: null,
            lista: [],
            listaCopia: [],
            errores:[]
        };
    },
    computed: {

        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        },
    },
    methods: {
        async searchMaestra(search) {
            if (search.length > 0 && search.toString().replace(/ /g, "") != "") {
                let data = await this.$apiService.index(`api/maestra/buscar/${search.toUpperCase()}`).then(data => data);
                return data;
            }
            return [];
        },
        async searchUser(search) {
            if (search.length > 0 && search.toString().replace(/ /g, "") != "") {
                let data = await this.$apiService.index(`api/user/buscar/${search.toUpperCase()}`).then(data => data);
                return data;
            }
            return [];
        },
        async searchCliente(search) {
            if (search.length > 0 && search.toString().replace(/ /g, "") != "") {
                let data = await this.$apiService.index(`api/cliente/buscar/${search.toUpperCase()}`).then(data => data);
                return data;
            }
            return null;
        },
        getValuesBoolean(values, index, codigo) {
            try {
                return values.split(';')[index];
            } catch (error) {
                console.log('Values con error del campo: '+codigo)
                return []
            }
        },
        getValues(values, codigo) {
            try {
                const lista = [];
                var datos = values.split('|');
                for (let i = 0; i < datos.length; i++) {
                let d = datos[i];
                lista.push({
                    texts: d.split(';')[0],
                    values: d.split(';')[1]
                });
                }
                return lista;
            } catch (error) {
                console.log('Values con error del campo: '+codigo)
                return []
            }
          },
        getPermiso(permiso) {
            return (this.permisos.some(e => e.child == permiso)) ? true : false;
        },
        filtrar(filtros, page) {
            let search = `offset=${(page-1)*10}&`;
            filtros.forEach((filtro) => {
                if (filtro.value != null && filtro.value.toString().replace(/ /g, "") != "") {
                    if (filtro.tipo == 'rango') {
                        //search = search + `${filtro.alias}=${filtro.campo},${filtro.value[0]},${filtro.value[1]}&`;
                        search = search + `${filtro.campo}=${filtro.value[0]},${filtro.value[1]}&`;
                        filtro.value = [];
                    } else {
                        search = search + `${filtro.campo}=${filtro.value}&`;
                    }
                }
            });
            return search;
        },
        datediff(date1, date2) {
            var y1 = date1.getFullYear(),
              m1 = date1.getMonth(),
              d1 = date1.getDate(),
              y2 = date2.getFullYear(),
              m2 = date2.getMonth(),
              d2 = date2.getDate();
            if (d1 < d2) {
              m1--;
              var date = new Date(y2, m2, 1, 12);
              date.setDate(0);
              d1 += date.getDate();
            }
            if (m1 < m2) {
              y1--;
              m1 += 12;
            }
            return [y1 - y2, m1 - m2, d1 - d2];
          },
        calcularEdad(fecha) {
            var fecha_hoy = new Date();
            var ahora_ano = fecha_hoy.getFullYear();
            var ahora_mes = fecha_hoy.getMonth();
            var ahora_dia = fecha_hoy.getDate();
            var values = fecha.split("-");
            var dia = parseInt(values[2]);
            var mes = parseInt(values[1]);
            var ano = parseInt(values[0]);
            var curd = new Date(ahora_ano, ahora_mes, ahora_dia);
            var cald = new Date(ano, mes - 1, dia);
            var dife = this.datediff(curd, cald);
            return dife[0] + " años, " + dife[1] + " meses y " + dife[2] + " días";
        },
        allowedDates: val => new Date(val.replace(/-+/g, '/')).getDay() != 0 && new Date(val.replace(/-+/g, '/')).getDay() != 6,
        currentDate(date) {
            const fecha = date;
            if (this.allowedDates(fecha.yyyymmdd())) {
                return fecha.yyyymmdd()
            } else {
                fecha.setDate(fecha.getDate() + 1);
                return this.currentDate(fecha);

            }
        },
        getErrores(error){
            if (error.response && 422 == error.response.status) {
                this.errores = error.response.data.errors;
            } else {
                if (error.response && 403 == error.response.status) {
                    this.$router.push('/403');
                } else {
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
            }
        }
    },
};