<template>
    <v-container>
        <v-row>
            <v-col col="12">
                <template v-for="route in menu">
                    <div v-if="(route.menu == true || ($cookies.get('user').role.indexOf('PACIENTE') > -1 && route.paciente))&& route.children.length ==0" class="mx-2 my-2 text-center primary--text" :key="route.PATH" style="float:left;width: 150px;height: 190px;">
                        <v-btn outlined fab color="info" left height="140" width="140" x-large :to="route">
                            <v-icon style="font-size:80px">{{route.icon}}</v-icon>
                        </v-btn>
                        {{route.name}}
                    </div>
                    <template v-for="subroute in route.children">
                        <div v-if="(subroute.menu == true || ($cookies.get('user').role.indexOf('PACIENTE') > -1 && subroute.paciente) ) && subroute.children.length ==0" class="mx-2 my-2 text-center primary--text" :key="subroute.PATH" style="float:left;width: 150px;height: 190px;">
                            <v-btn outlined fab color="info" left height="140" width="140" x-large :to="subroute">
                                <v-icon style="font-size:80px">{{subroute.icon}}</v-icon>
                            </v-btn>
                            {{subroute.name}}
                        </div>
                        <template v-for="subsubroute in subroute.children">
                            <div v-if="(subsubroute.menu == true || ($cookies.get('user').role.indexOf('PACIENTE') > -1 && subsubroute.paciente) ) && subsubroute.children.length ==0" class="mx-2 my-2 text-center primary--text" :key="subsubroute.PATH" style="float:left;width: 150px;height: 190px;">
                                <v-btn outlined fab color="info" left height="140" width="140" x-large :to="subsubroute">
                                    <v-icon style="font-size:80px">{{subsubroute.icon}}</v-icon>
                                </v-btn>
                                {{subsubroute.name}}
                            </div>
                        </template>
                    </template>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {

  data() {
    return {
      menu: [],
      userMenu: [],

      ancho: (typeof window !== 'undefined') ? window.outerWidth : 517,
    }
  },
  created: async function() {
    this.token = this.$cookies.get("token");
    this.user = this.$cookies.get("user");
    this.userMenu = []
    for (let iterator of this.$cookies.get("user").role.split(',')) {
      console.log(iterator)
      let userMenu = await this.$apiService.usermenu({
        parent: iterator
      }).then(data => data);
      this.userMenu = this.userMenu.concat(userMenu)
    }
    if (this.userMenu.length > 0) {
      this.$router.push({
        path: '/login'
      });
    } else {
      this.menu = this.$router.options.routes;
      this.menu.forEach((route) => {
        route.menu = false;

        if (this.userMenu.find(e => e.child == route.permiso) != undefined || (route.children.length > 0 && route.permiso == '') || (this.$cookies.get('user').role.indexOf('PACIENTE') > -1 && route.paciente)) {
          if (this.userMenu.find(e => e.child == route.permiso) != undefined || route.paciente) {
            route.menu = true;
          }

          if (route.children.length > 0) {
            route.children.forEach((subroute) => {

              if (this.userMenu.find(e => e.child == subroute.permiso) != undefined || (subroute.children.length > 0 && subroute.permiso == '') || (this.$cookies.get('user').role.indexOf('PACIENTE') > -1 && subroute.paciente)) {

                if (this.userMenu.find(e => e.child == subroute.permiso) != undefined || subroute.paciente) {
                  route.menu = true;
                  subroute.menu = true;
                }
                if (subroute.children.length > 0) {
                  subroute.menu = false;
                  subroute.children.forEach((subsubroute) => {
                    if (this.userMenu.find(e => e.child == subsubroute.permiso) != undefined || (this.$cookies.get('user').role.indexOf('PACIENTE') > -1 && subsubroute.paciente)) {

                      if (this.userMenu.find(e => e.child == subsubroute.permiso) != undefined || (this.$cookies.get('user').role.indexOf('PACIENTE') > -1 && subsubroute.paciente)) {
                        subroute.menu = true;
                        subsubroute.menu = true;
                      }
                    } else {
                      subsubroute.menu = false;
                    }

                  });
                }

              } else {
                subroute.menu = false;
              }

            });
          }
        } else {
          route.menu = false;

        }
      });
    }
    this.cargado = true;

  }
}

</script>

<style>

</style>
