import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "base" */ '../views/Layout'),
    menu: false,
    desplegable: false,
    icon: '',
    redirect: '/inicio',
    meta: {
      permiso: [],
      requiresSession: true,
      title: 'Inicio',
    },
    children: [
      {
        path: '/inicio',
        name: 'Inicio',
        desplegable: false,
        icon: 'home',
        menu: false,
        meta: {
          permiso: [],
          title: 'Inicio',
          requiresSession: true,
        },
        children: [],
        component: () => import(/* webpackChunkName: "base" */ '../views/Home.vue'),
      },
      {
        path: '/perfil',
        name: 'Perfil',
        menu: false,
        desplegable: false,
        meta: {
          permiso: [],
          title: 'Perfil',
          requiresSession: true,
        },
        icon: 'home',
        children: [],
        component: () => import(/* webpackChunkName: "base" */ '../views/PerfilUsuario.vue'),
      },
      {
        path: '/menu',
        name: 'Menu',
        menu: false,
        desplegable: false,
        meta: {
          permiso: [],
          requiresSession: true,
          title: 'Inicio',
        },
        children: [],
        component: () => import(/* webpackChunkName: "base" */ '../components/Menu.vue'),
      },
      {
        path: '/403',
        name: 'Error403',
        menu: false,
        desplegable: false,
        meta: {
          permiso: [],
          requiresSession: true,
          title: 'No autorizado',
        },
        children: [],
        component: () => import(/* webpackChunkName: "base" */ '../views/auth/Error403.vue'),
      },
      {
        path: '/admisionesrapida',
        name: 'Admision',
        component: () => import(/* webpackChunkName: "admision" */ '../views/admisionrapida/Admisionrapida.vue'),
        icon: 'mdi-clock-fast',
        menu: true,
        meta: {
          permiso: ['verAdmisiones'],
          requiresSession: true,
          title: 'Admision rapida',
        },
        children: []
      },
      {
        path: '/admisionescitas',
        name: 'Admision cita',
        component: () => import(/* webpackChunkName: "admision" */ '../views/admisionrapida/Admisioncita.vue'),
        icon: '',
        menu: false,
        meta: {
          permiso: ['verAdmisiones'],
          requiresSession: true,
          title: '',
        },
        children: []
      }
      ,
      {
        path: '/admisionesfacturas',
        name: 'Admision factura',
        component: () => import(/* webpackChunkName: "admision" */ '../views/admisionrapida/Admisionfactura.vue'),
        icon: '',
        menu: false,
        meta: {
          permiso: ['verAdmisiones'],
          requiresSession: true,
          title: '',
        },
        children: []
      },
      {
        path: '/admisiones',
        name: 'Agendamiento',
        component: () => import(/* webpackChunkName: "admision" */ '../views/citas/Admisiones.vue'),
        icon: 'calendar_today',
        menu: true,
        meta: {
          permiso: ['verAdmisiones'],
          requiresSession: true,
          title: 'Admisiones',
        },
        children: []
      },
      {
        path: '/citaspanel/:id',
        name: 'Citaspanel',
        component: () => import(/* webpackChunkName: "admision" */ '../views/citas/Panel.vue'),
        icon: '',
        menu: false,
        meta: {
          permiso: ['verAdmisiones'],
          requiresSession: true,
          title: 'Admisiones',
        },
        children: []
      },
      {
        path: '/asistencial',
        name: 'Asistenciales',
        component: () => import(/* webpackChunkName: "asistenciales" */ '../views/index.vue'),
        icon: 'mdi-stethoscope',
        menu: true,
        desplegable: true,
        meta: {
          permiso: ['verHistorias', 'verLaboratorios', 'verProcedimientos', 'verOrdenesservicios'],
          requiresSession: true,
          title: 'Asistenciales',
        },
        children: [
          {
            path: '/atencion',
            name: 'Atenciones',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/asistencial/Atencion.vue'),
            icon: 'mdi-hospital-box-outline',
            menu: true,
            meta: {
              permiso: ['verHistorias'],
              requiresSession: true,
              title: 'Atenciones',
            },
            children: []
          },
          {
            path: '/laboratorio',
            name: 'Laboratorios',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/laboratorios/Laboratorios.vue'),
            icon: 'mdi-format-color-fill',
            menu: true,
            meta: {
              permiso: ['verLaboratorios'],
              requiresSession: true,
              title: 'Laboratorios',
            },
            children: []
          },
          {
            path: '/ordenes/:id',
            name: 'Ordenes',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/laboratorios/Ordenes.vue'),
            icon: '',
            menu: false,
            meta: {
              permiso: [],
              requiresSession: true,
              title: '',
            },
            children: []
          },
          {
            path: '/laboratoriopanel/:id',
            name: 'Laboratoriopanel',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/laboratorios/Panel.vue'),
            icon: '',
            menu: false,
            meta: {
              permiso: [],
              requiresSession: true,
              title: '',
            },
            children: []
          },
          {
            path: '/crearlaboratorio',
            name: 'Crearlaboratorio',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/laboratorios/Create.vue'),
            icon: '',
            menu: false,
            meta: {
              permiso: [],
              requiresSession: true,
              title: '',
            },
            children: []
          },
          {
            path: '/editarlaboratorio/:id',
            name: 'Editarlaboratorio',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/laboratorios/CreateLaboratorio.vue'),
            icon: '',
            menu: false,
            meta: {
              permiso: [],
              requiresSession: true,
              title: '',
            },
            children: []
          },
          {
            path: '/procedimiento',
            name: 'Procedimientos',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/procedimientos/Procedimientos.vue'),
            icon: 'mdi-account-box',
            menu: true,
            meta: {
              permiso: ['verProcedimientos'],
              requiresSession: true,
              title: 'Procedimientos',
            },
            children: []
          },
          {
            path: '/crearprocedimiento',
            name: 'Crearprocedimiento',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/procedimientos/Create.vue'),
            icon: '',
            menu: false,
            meta: {
              permiso: [],
              requiresSession: true,
              title: 'Procedimientos',
            },
            children: []
          },
          {
            path: '/ordenservicio',
            name: 'Ordenes de servicios',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/ordenesservicios/Ordenservicio.vue'),
            icon: 'mdi-mouse-variant',
            menu: true,
            meta: {
              permiso: ['verOrdenesservicios'],
              requiresSession: true,
              title: 'Ordenes de servicios',
            },
            children: []
          },
          {
            path: '/crearhistoria/:id/:servicio',
            name: 'Createhistoria',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/asistencial/Create.vue'),
            icon: '',
            menu: false,
            meta: {
              permiso: [],
              requiresSession: true,
              title: '',
            },
            children: []
          },
          {
            path: '/crearhistoria/:id/:servicio/:triage',
            name: 'CreatehistoriaTriage',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/asistencial/Create.vue'),
            icon: '',
            menu: false,
            meta: {
              permiso: [],
              requiresSession: true,
              title: '',
            },
            children: []
          },
          {
            path: '/atencionespanel/:id',
            name: 'Atencionespanel',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/asistencial/Panel.vue'),
            icon: '',
            menu: false,
            meta: {
              permiso: [],
              requiresSession: true,
              title: '',
            },
            children: []
          },
        ]
      },

      {
        path: '/admisionespacientes',
        name: 'Admisiones paciente',
        component: () => import(/* webpackChunkName: "admision" */ '../views/Externo/Admisiones.vue'),
        icon: 'calendar_today',
        menu: false,
        paciente: true,
        meta: {
          permiso: [],
          requiresSession: true,
          title: '',
        },
        children: []
      },
      {
        path: '/asistencialpacientes',
        name: 'Asistenciales paciente',
        component: () => import(/* webpackChunkName: "asistenciales" */ '../views/index.vue'),
        icon: 'mdi-stethoscope',
        menu: false,
        paciente: false,
        meta: {
          permiso: [],
          requiresSession: true,
          title: '',
        },
        children: [

          {
            path: '/laboratoriopacientes',
            name: 'Laboratorios paciente',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/Externo/Laboratorios.vue'),
            icon: 'mdi-format-color-fill',
            menu: false,
            paciente: true,
            meta: {
              permiso: [],
              requiresSession: true,
              title: '',
            },
            children: []
          },
          {
            path: '/laboratoriopanelpacientes/:id',
            name: 'Laboratoriopanelpacientes',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/Externo/Panel.vue'),
            icon: '',
            menu: false,
            meta: {
              permiso: [],
              requiresSession: true,
              title: '',
            },
            children: []
          },

          {
            path: '/procedimientopacientes',
            name: 'Procedimientos paciente',
            component: () => import(/* webpackChunkName: "asistenciales" */ '../views/Externo/Procedimientos.vue'),
            icon: 'mdi-account-box',
            menu: false,
            paciente: true,
            meta: {
              permiso: [],
              requiresSession: true,
              title: '',
            },
            children: []
          },

        ]
      },
      {
        path: '/pacienteslista',
        name: 'PacientesLista',
        component: () => import(/* webpackChunkName: "admision" */ '../views/pacientes/Bpaciente.vue'),
        icon: 'mdi-briefcase-account',
        menu: true,
        meta: {
          permiso: ['verPacientes'],
          requiresSession: true,
          title: 'Pacientes',
        },
        children: []
      },
      {
        path: '/pacienteslista/panel/:copeId/:tipo',
        name: 'PanelPacientesLista',
        component: () => import(/* webpackChunkName: "farmacia" */ '../views/pacientes/Panel.vue'),
        icon: 'descripion',
        menu: false,
        meta: {
          permiso: ['verPacientes'],
          requiresSession: true,
          title: 'Panel de pacientes',
        },
        children: []
      },
      {
        path: '/vacunacion',
        name: 'Vacunacion',
        component: () => import(/* webpackChunkName: "admision" */ '../views/vacunas/Vacunas.vue'),
        icon: 'mdi-needle',
        menu: true,
        meta: {
          permiso: ['verVacunas'],
          requiresSession: true,
          title: 'Vacunacion',
        },
        children: []
      }
      ,
      {
        path: '/crearvacuna/:coppId',
        name: 'Crearvacuna',
        component: () => import(/* webpackChunkName: "farmacia" */ '../views/vacunas/create.vue'),
        icon: 'descripion',
        menu: false,
        meta: {
          permiso: ['verVacunas'],
          requiresSession: true,
          title: 'Apartar vacuna',
        },
        children: []
      },
      {
        path: '/vacunas/:coppId',
        name: 'Panelvacunas',
        component: () => import(/* webpackChunkName: "farmacia" */ '../views/vacunas/Panel.vue'),
        icon: 'descripion',
        menu: false,
        meta: {
          permiso: ['verVacunas'],
          requiresSession: true,
          title: 'Panel de vacunas',
        },
        children: []
      },
      {
        path: '/tramitarvacuna/:id/:servicio/:titulo',
        name: 'Tramitarvacuna',
        component: () => import(/* webpackChunkName: "farmacia" */ '../views/vacunas/Tramitar.vue'),
        icon: 'descripion',
        menu: false,
        meta: {
          permiso: ['verVacunas'],
          requiresSession: true,
          title: 'Tramitar vacuna',
        },
        children: []
      },
      {
        path: '/inventario',
        name: 'Inventario',
        component: () => import(/* webpackChunkName: "farmacia" */ '../views/index.vue'),
        icon: 'mdi-archive-outline',
        menu: true,
        desplegable: true,
        meta: {
          permiso: ['verInsumos'],
          requiresSession: true,
          title: 'Inventario',
        },
        children: [
          {
            path: 'inventario/insumos',
            name: 'Insumos',
            component: () => import(/* webpackChunkName: "farmacia" */ '../views/insumos/Insumo.vue'),
            icon: 'mdi-archive-outline',
            menu: true,
            meta: {
              permiso: ['verInsumos'],
              requiresSession: true,
              title: 'insumos',
            },
            children: []
          },
          {
            path: 'inventario/panel/:id',
            name: 'Insupanel',
            component: () => import(/* webpackChunkName: "farmacia" */ '../views/insumos/Insupanel.vue'),
            icon: 'description',
            menu: false,
            meta: {
              permiso: ['verInsumos'],
              requiresSession: true,
              title: 'insumos panel',
            },
            children: []
          },

        ]
      },
      {
        path: '/farmacia',
        name: 'Farmacia',
        component: () => import(/* webpackChunkName: "farmacia" */ '../views/index.vue'),
        icon: 'create_new_folder',
        menu: true,
        desplegable: true,
        meta: {
          permiso: ['verMedicamentos'],
          requiresSession: true,
          title: 'Farmacia',
        },
        children: [
          {
            path: '/entregafactura',
            name: 'Entregafactura',
            component: () => import(/* webpackChunkName: "farmacia" */ '../views/farmacia/EntregaFactura.vue'),
            icon: '',
            menu: false,
            meta: {
              permiso: [],
              requiresSession: true,
              title: 'Farmacia',
            },
            children: []
          },
          {
            path: 'medicamentos',
            name: 'Entrega de Medicamentos',
            component: () => import(/* webpackChunkName: "farmacia" */ '../views/farmacia/Entregapanel.vue'),
            icon: 'mdi-briefcase-minus-outline',
            menu: true,
            meta: {
              permiso: ['verAdmisionesmedicamentos'],
              requiresSession: true,
              title: 'Entrega de Medicamentos',
            },
            children: []
          },
          {
            path: 'medicamento',
            name: 'Medicamentos',
            component: () => import(/* webpackChunkName: "farmacia" */ '../views/farmacia/Medicamento.vue'),
            icon: 'mdi-pill',
            menu: true,
            meta: {
              permiso: ['verMedicamentos'],
              requiresSession: true,
              title: 'medicamentos',
            },
            children: []
          },
          {
            path: 'medicamento/panel/:id',
            name: 'Medipanel',
            component: () => import(/* webpackChunkName: "farmacia" */ '../views/farmacia/Medipanel.vue'),
            icon: 'description',
            menu: false,
            meta: {
              permiso: ['verMedicamentos'],
              requiresSession: true,
              title: 'medicamentos panel',
            },
            children: []
          },

        ]
      },
      {
        path: '/demanda',
        name: 'Demanda inducida',
        component: () => import(/* webpackChunkName: "demanda" */ '../views/demanda/Demanda.vue'),
        icon: 'mdi-account-multiple-check',
        menu: true,
        meta: {
          permiso: ['verDemanda'],
          requiresSession: true,
          title: 'Demanda inducida',
        },
        children: [],
      },
      {
        path: '/tutoriales',
        name: 'Tutoriales',
        component: () => import(/* webpackChunkName: "videos" */ '../views/videos/Videos.vue'),
        icon: 'mdi-play-box-outline',
        desplegable: false,
        menu: true,
        meta: {
          permiso: [],
          requiresSession: true,
          title: 'Tutoriales',
        },
        children: [],
      },
      {
        path: '/destiondocumental',
        name: 'Gestiondocumental',
        component: () => import(/* webpackChunkName: "videos" */ '../views/videos/Videos.vue'),
        icon: 'mdi-text-box-multiple',
        desplegable: false,
        menu: true,
        meta: {
          permiso: [],
          requiresSession: true,
          title: 'Gestion documental',
        },
        children: [],
      },
      {
        path: '/facturaciones',
        name: 'Facturaciones',
        component: () => import(/* webpackChunkName: "facturaciones" */ '../views/index.vue'),
        icon: 'monetization_on',
        menu: true,
        desplegable: true,
        meta: {
          title: 'Facturaciones',
          requiresSession: true,
          permiso: ['verCuentascobros'],
        },
        children: [
          {
            path: 'cuentascobros',
            name: 'Cuentas cobro',
            component: () => import(/* webpackChunkName: "facturaciones" */ '../views/cuentas/CuentasCobro.vue'),
            icon: 'format_color_fill',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Panel de c. cobros',
              requiresSession: true,
              permiso: ['verCuentascobros'],
            },
            children: []
          },
          {
            path: '/cuentascobrosfacturas/:id',
            name: 'Cuentascobrospanel',
            component: () => import(/* webpackChunkName: "facturaciones" */ '../views/cuentas/CuentasCobroFacturaciones.vue'),
            icon: '',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Panel de c. cobros',
              requiresSession: true,
              permiso: ['verCuentascobros'],
            },
            children: []
          },
        ]
      },
      {
        path: '/encuestas',
        name: 'Encuestas',
        component: () => import(/* webpackChunkName: "encuestas" */ '../views/encuestas/Encuesta.vue'),
        icon: 'mdi-file-document',
        menu: true,
        desplegable: false,
        meta: {
          permiso: ['verEncuestas'],
          requiresSession: true,
          title: 'Encuestas',
        },
        children: [
        ]
      },
      {
        path: 'crearencuesta/:id/:encuesta',
        name: 'Createencuesta',
        component: () => import(/* webpackChunkName: "encuestas" */ '../views/encuestas/Create.vue'),
        icon: '',
        menu: false,
        meta: {
          permiso: [],
          requiresSession: true,
          title: '',
        },
        children: []
      },
      {
        path: '/encuestas/panel/:id',
        name: 'Encuestaspanel',
        component: () => import(/* webpackChunkName: "encuestas" */ '../views/encuestas/Panel.vue'),
        icon: '',
        menu: false,
        meta: {
          permiso: [],
          requiresSession: true,
          title: '',
        },
        children: []
      },
      {
        path: '/reportes',
        name: 'Reportes',
        component: () => import(/* webpackChunkName: "reportes" */ '../views/index.vue'),
        icon: 'mdi-file-table-box-multiple-outline',
        menu: true,
        desplegable: true,
        meta: {
          title: 'Reportes',
          requiresSession: true,
          permiso: ['verReportes'],
        },
        children: [
          {
            path: '/reportesfacturas',
            name: 'Panel de reportes',
            component: () => import('../views/reportes/Reportes.vue'),
            icon: 'mdi-cash-multiple',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Panel de reportes',
              requiresSession: true,
              permiso: ['verReportes'],
            },
            children: [

            ]
          },
          {
            path: '/reportesmultiples',
            name: 'ReportesMultiples',
            component: () => import(/* webpackChunkName: "reportes" */ '../views/reportes/ReportesMultiples.vue'),
            icon: 'mdi-cash-multiple',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Reportes Multiples',
              requiresSession: true,
              permiso: ['verReportes'],
            },
            children: [

            ]
          },
          {
            path: '/reportesripslaboratorio',
            name: 'Rips laboratorios',
            component: () => import(/* webpackChunkName: "reportes" */ '../views/reportes/Ripslaboratorio.vue'),
            icon: 'mdi-cash-multiple',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Rips laboratorios',
              requiresSession: true,
              permiso: ['verReportes'],
            },
            children: [

            ]
          },
          {
            path: '/reportesrips',
            name: 'Rips',
            component: () => import(/* webpackChunkName: "reportes" */ '../views/reportes/ReportesRips.vue'),
            icon: 'mdi-cash-multiple',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Rips',
              requiresSession: true,
              permiso: ['verReportes'],
            },
            children: [

            ]
          },
        ]
      },
      {
        path: '/contratacion',
        name: 'Contratacion',
        component: () => import(/* webpackChunkName: "contratacion" */ '../views/index.vue'),
        icon: 'mdi-apps',
        menu: true,
        desplegable: true,
        meta: {
          title: 'Contratacion',
          requiresSession: true,
          permiso: ['verContratos'],
        },
        children: [
          {
            path: '/contratacion/contratos',
            name: 'Contratatos',
            component: () => import(/* webpackChunkName: "contratacion" */ '../views/contratacion/Contratos.vue'),
            icon: 'mdi-apps',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Contratacion',
              requiresSession: true,
              permiso: ['verContratos'],
            },
            children: []
          },
          {
            path: '/contratacion/cup',
            name: 'Cups',
            component: () => import(/* webpackChunkName: "contratacion" */ '../views/contratacion/Cup.vue'),
            icon: 'mdi-format-list-text',
            menu: true,
            desplegable: false,
            meta: {
              title: 'cups',
              requiresSession: true,
              permiso: ['verCups'],
            },
            children: []
          },

          {
            path: '/contratacion/tarifario',
            name: 'Tarifarios',
            component: () => import(/* webpackChunkName: "contratacion" */ '../views/contratacion/Tarifario.vue'),
            icon: 'mdi-format-list-checkbox',
            menu: true,
            desplegable: false,
            meta: {
              title: 'tarifarios',
              requiresSession: true,
              permiso: ['verTarifarios'],
            },
            children: []
          },

          {
            path: '/contratacion/servicio',
            name: 'Servicios',
            component: () => import(/* webpackChunkName: "contratacion" */ '../views/contratacion/Servicio.vue'),
            icon: 'mdi-format-list-checks',
            menu: true,
            desplegable: false,
            meta: {
              title: 'servicios',
              requiresSession: true,
              permiso: ['verServicios'],
            },
            children: []
          },
        ]
      },
      {
        path: '/sistema/sms',
        name: 'Sms',
        component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/Sms.vue'),
        icon: 'mdi-email',
        menu: true,
        desplegable: false,
        meta: {
          title: 'Sms',
          requiresSession: true,
          permiso: ['verSms'],
        },
        children: [
        ]
      },
      {
        path: '/sistema',
        name: 'Configuraciones',
        component: () => import(/* webpackChunkName: "sistema" */ '../views/index.vue'),
        icon: 'mdi-cogs',
        menu: true,
        desplegable: true,
        meta: {
          title: 'configuraciones',
          requiresSession: true,
          permiso: ['verMaestro', 'verPacientes', 'verBasedatos', 'verEmpleados', 'verRepresentantes', 'verEmpresas', 'verHistoriasecciones', 'verPaquetes', 'verReportes', 'verUser', 'verRol'],
        },
        children: [
          {
            path: '/sistema/maestra',
            name: 'Maestra',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/Maestra/Maestra.vue'),
            icon: 'mdi-apps',
            menu: true,
            desplegable: false,
            meta: {
              title: 'maestra',
              requiresSession: true,
              permiso: ['verMaestro'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/maestra/tabla/:id',
            name: 'MaestraTabla',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/Maestra/Tabla.vue'),
            icon: 'mdi-apps',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Tabla',
              requiresSession: true,
              permiso: ['verMaestro'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/pacientes',
            name: 'Pacientes',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/pacientes/Paciente.vue'),
            icon: 'mdi-briefcase-account',
            menu: true,
            desplegable: false,
            meta: {
              title: 'pacientes',
              requiresSession: true,
              permiso: ['verPacientes'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/pacientes/create',
            name: 'Pacientescreate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/pacientes/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Crear paciente',
              requiresSession: true,
              permiso: ['verPacientes'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/pacientes/update/:id',
            name: 'Pacientesupdate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/pacientes/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Actualizar paciente',
              requiresSession: true,
              permiso: ['verPacientes'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/basededatos',
            name: 'Base de datos',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/basededatos/Basededatos.vue'),
            icon: 'mdi-account',
            menu: true,
            desplegable: false,
            meta: {
              title: 'base de datos',
              requiresSession: true,
              permiso: ['verBasedatos'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/basededatos/panel/:id',
            name: 'Bdpanel',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/basededatos/Panel.vue'),
            icon: 'mdi-account',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Panel base de datos',
              requiresSession: true,
              permiso: ['verBasedatos'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/empleados',
            name: 'Empleados',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/empleados/Empleado.vue'),
            icon: 'mdi-account-tie',
            menu: true,
            desplegable: false,
            meta: {
              title: 'empleados',
              requiresSession: true,
              permiso: ['verEmpleados'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/empleados/create',
            name: 'Empleadoscreate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/empleados/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Crear empleados',
              requiresSession: true,
              permiso: ['verEmpleados'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/empleados/update/:id',
            name: 'Empleadosupdate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/empleados/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Actualizar empleados',
              requiresSession: true,
              permiso: ['verEmpleados'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/empleados/panel/:id',
            name: 'Empleadospanel',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/empleados/Panel.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Panel empleados',
              requiresSession: true,
              permiso: ['verEmpleados'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/representantes',
            name: 'Representantes',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/representantes/Representante.vue'),
            icon: 'mdi-briefcase-account',
            menu: false,
            desplegable: false,
            meta: {
              title: 'representantes',
              requiresSession: true,
              permiso: ['verRepresentantes'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/representantes/create',
            name: 'Representantecreate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/representantes/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Crear representante',
              requiresSession: true,
              permiso: ['verRepresentantes'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/representantes/update/:id',
            name: 'Representanteupdate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/representantes/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Actualizar representante',
              requiresSession: true,
              permiso: ['verRepresentantes'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/empresa',
            name: 'Empresas',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/Empresa.vue'),
            icon: 'mdi-account-supervisor',
            menu: true,
            desplegable: false,
            meta: {
              title: 'empresas',
              requiresSession: true,
              permiso: ['verEmpresas'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/documentos',
            name: 'Documentos',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/documentos/documentos.vue'),
            icon: 'mdi-cube',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Documentos',
              requiresSession: true,
              permiso: ['verDocumentos'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/documentos/create/:codoId',
            name: 'Doccreate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/documentos/CreateDocumento.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Crear Documentos',
              requiresSession: true,
              permiso: ['verDocumentos'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/encuestas',
            name: 'Encuestassecciones',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/encuestassecciones/Encuestassecciones.vue'),
            icon: 'mdi-cube',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Encuestas',
              requiresSession: true,
              permiso: ['verConfiguracionEncuesta'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/encuestas/createnuevo',
            name: 'Encureatenuevo',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/encuestassecciones/CreateEncuesta.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Crear formulario',
              requiresSession: true,
              permiso: ['verConfiguracionEncuesta'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/encuestas/updatenuevo/:id',
            name: 'Encutupdatenuevo',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/encuestassecciones/CreateEncuesta.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Actualizar formulario',
              requiresSession: true,
              permiso: ['verConfiguracionEncuesta'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/historias',
            name: 'Historias',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/historias/Historiasecciones.vue'),
            icon: 'mdi-cube',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Historias',
              requiresSession: true,
              permiso: ['verHistoriasecciones'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/historias/create',
            name: 'Histcreate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/historias/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Crear formulario',
              requiresSession: true,
              permiso: ['verHistoriasecciones'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/historias/createnuevo',
            name: 'Histcreatenuevo',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/historias/CreateHistoria.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Crear formulario',
              requiresSession: true,
              permiso: ['verHistoriasecciones'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/historias/updatenuevo/:id',
            name: 'Histupdatenuevo',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/historias/CreateHistoria.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Actualizar formulario',
              requiresSession: true,
              permiso: ['verHistoriasecciones'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/historias/update/:id',
            name: 'Histupdate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/historias/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Actualizar formulario',
              requiresSession: true,
              permiso: ['verHistoriasecciones'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/paquetes',
            name: 'Paquetes',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/paquetes/Paquete.vue'),
            icon: 'mdi-cube',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Paquetes',
              requiresSession: true,
              permiso: ['verPaquetes'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/paquetes/create',
            name: 'Paquetecreate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/paquetes/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Crear paquete',
              requiresSession: true,
              permiso: ['verPaquetes'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/paquetes/update/:id',
            name: 'Paqueteupdate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/paquetes/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Actualizar paquete',
              requiresSession: true,
              permiso: ['verPaquetes'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/formularios',
            name: 'Formularios',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/formularios/Formulariosecciones.vue'),
            icon: 'mdi-cube',
            menu: true,
            desplegable: false,
            meta: {
              title: 'formularios',
              requiresSession: true,
              permiso: ['verHistoriasecciones'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/formularios/create',
            name: 'Formcreate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/formularios/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Crear formulario',
              requiresSession: true,
              permiso: ['verHistoriasecciones'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/formularios/update/:id',
            name: 'Formupdate',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/formularios/Create.vue'),
            icon: 'mdi-cube',
            menu: false,
            desplegable: false,
            meta: {
              title: 'Actualizar formulario',
              requiresSession: true,
              permiso: ['verHistoriasecciones'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/reportes',
            name: 'Configuracion de Reportes',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/Reportes/Reportes.vue'),
            icon: 'mdi-apps',
            menu: true,
            desplegable: false,
            meta: {
              title: 'reportes',
              requiresSession: true,
              permiso: ['verReportes'],
            },
            children: [

            ]
          },
          {
            path: '/sistema/reportes/view/:id',
            name: 'ViewReporte',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/Reportes/ReportesView.vue'),
            icon: 'mdi-apps',
            menu: false,
            desplegable: false,
            meta: {
              title: 'reporte',
              requiresSession: true,
              permiso: ['verReportes'],
            },
            children: [

            ]
          },
         
          {
            path: '/sistema/chatbot',
            name: 'Chatbot',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/Chatbot.vue'),
            icon: 'mdi-robot',
            menu: true,
            desplegable: false,
            meta: {
              title: 'Chatbot',
              requiresSession: true,
              permiso: ['verEmpresas'],
            },
            children: [
            ]
          },

        ]
      },
      
      {
        path: '/sistema/acceso',
        name: 'Control de accesos',
        component: () => import(/* webpackChunkName: "sistema" */ '../views/index.vue'),
        icon: 'mdi-account',
        menu: true,
        desplegable: true,
        meta: {
          title: 'accesos',
          requiresSession: true,
          permiso: ['verUser', 'verRol'],
        },
        children: [
          {
            path: '/sistema/acceso/usuarios',
            name: 'Usuarios',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/control/User.vue'),
            icon: 'mdi-account',
            menu: true,
            desplegable: false,
            meta: {
              title: 'usuarios',
              permiso: ['verUser'],
              requiresSession: true,
            },
            children: []
          },
          {
            path: '/sistema/acceso/perfiles',
            name: 'Perfiles',
            component: () => import(/* webpackChunkName: "sistema" */ '../views/sistema/control/Roles.vue'),
            icon: 'mdi-badge-account-horizontal',
            menu: true,
            desplegable: false,
            meta: {
              title: 'perfiles',
              permiso: ['verRol'],
              requiresSession: true,
            },
            children: []
          }
        ]
      },
    ]
  },
  {
    path: '/laboratorio-certificado/:entidad/:id',
    component: () => import(/* webpackChunkName: "certificado" */ '../views/LaboratioCertificado'),
    name: 'LaboratorioCertificado',
    menu: false,
    icon: '',
    meta: {
      permiso: [],
      requiresSession: false,
      title: 'laboratorio-certificado',
    },
    children: []
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "auth" */ '../views/index'),
    menu: false,
    desplegable: false,
    icon: '',
    redirect: '/login',
    meta: {
      permiso: [],
      requiresSession: true,
      title: 'Login',
    },
    children: [
      {
        path: '/login',
        component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login'),
        name: 'login',
        menu: false,
        icon: '',
        meta: {
          permiso: [],
          requiresSession: false,
          title: 'Login',
        },
        children: []
      },
      {
        path: '/registrar',
        component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Registrarse'),
        name: 'registrar',
        menu: false,
        icon: '',
        meta: {
          permiso: [],
          requiresSession: false,
          title: 'Registrar',
        },
        children: []
      },
      {
        path: '/turnos',
        component: () => import(/* webpackChunkName: "auth" */ '../views/layout/turno'),
        name: 'turnos',
        menu: false,
        icon: '',
        meta: {
          permiso: [],
          requiresSession: true,
          title: 'turnos',
        },
        children: []
      },
      {
        path: "*",
        component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Error403'),
        name: '403',
        menu: false,
        icon: '',
        meta: {
          permiso: [],
          requiresSession: false,
          title: 'Pagina no encontrada',
        },
        children: [

        ]
      },
    ]
  }
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router